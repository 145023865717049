import { createAction } from 'redux-actions'
import { LOGIN_USER, LOGOUT_USER, SET_HIGH_CONTRAST, LOGIN_USER_ERROR} from '../constants/ActionTypes'
import { selectLang } from './OrderActions'
import Odoo from '../utils/Odoo'

export const logUserOut = createAction(LOGOUT_USER)

export function setHighContrast(high_contrast) {
  return dispatch => {
    dispatch({
      type: SET_HIGH_CONTRAST,
      payload: high_contrast
    })
  }
}

export function logUserIn(token) {
  return dispatch => {
    let lang = false
    if(window.location.search.includes('lang=')){
      lang = new URLSearchParams(window.location.search).get('lang').replace('-', '_')
    }
    if(window.location.hash.includes('lang=')){
      lang = window.location.hash.split('lang=')[1].replace('-', '_')
    }
    let params = {
      'client_token': token
    }
    if(lang){
      params.lang = lang
    }
    Odoo.login('order', params).then(session => {
      if(!window.location.search.includes('lang=') && !window.location.hash.includes('lang=')){
        dispatch(selectLang((session.data.lang==='pl_PL' || session.data.lang===false) ? 'pl-PL' : 'en-US'))
      }
      dispatch({
        type: LOGIN_USER,
        payload: { ...session }
      })
    }).catch(error => {
      dispatch({
        type: LOGIN_USER_ERROR,
        message: error.message
      })
    })
  }
}

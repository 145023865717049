import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'

class Prompt extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: ''
        }
    }

    onChange = (e) =>{
        let value = e.target.value
        const {onChange} = this.props
        onChange(value)
        this.setState({value: value})
    }

    handleEnter = (event) => {
      const {value} = this.state
      const {callback} = this.props
      if (event.which === 13 && value) {
        callback(value)
        Popup.close()
      }
    }

    render() {
        return (
          <>
            <p>{this.props.question}</p><br/>
            <input
              type="text"
              className="i-popup__input"
              value={this.state.value}
              onChange={this.onChange}
              onKeyPress={this.handleEnter} />
          </>
        )
    }
}

Popup.registerPlugin('prompt', function (question, callback) {
    let promptValue = null
    let promptChange = function (value) {
        promptValue = value
    };

    this.create({
        content: <Prompt onChange={promptChange} callback={callback} question={question} />,
        buttons: {
          right: [{
            text: 'OK',
            key: 'enter',
            action: function () {
              if(promptValue){
                callback(promptValue)
                Popup.close()
              }
            }
          },
          {
            text: intl.get('Anuluj'),
            className: 'cancel-button',
            action: function () {
              Popup.close()
            }
          }]
        }
    })
})

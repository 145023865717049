// Common
export const API_ERROR = 'API_ERROR'
export const SET_LANG = 'SET_LANG'
export const SET_HIGH_CONTRAST = 'SET_HIGH_CONTRAST'

// Authentication
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'

//Packs
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const REVEAL_NAME = 'REVEAL_NAME'
export const PRINT_INVOICE = 'PRINT_INVOICE'

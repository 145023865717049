import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { LOGIN_USER, SEND_MESSAGE, REVEAL_NAME, API_ERROR } from '../constants/ActionTypes'

const initialState = {
  data: false
}

export default function pack(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state,
        data: action.payload.data
      }
    case SEND_MESSAGE:
      return { ...state,
        data: {
          ...state.data,
          messages: [
            action.payload,
            ...state.data.messages || []
          ]
        }
      }
    case REVEAL_NAME:
      if (!action.payload){
        Popup.alert(intl.get('Nieprawidłowe dane'))
        return { ...state }
      }
      return { ...state,
        data: {
          ...state.data,
          delivery_name: action.payload.delivery_name,
          invoice_name: action.payload.invoice_name,
          delivery_street: action.payload.delivery_street,
          invoice_street: action.payload.invoice_street,
          invoice_vat: action.payload.invoice_vat,
          revealed: true
        }
      }
    case API_ERROR:
      Popup.alert(action.message || intl.get('Wystąpił nieznany błąd'))
      return { ...state }
    default:
      return state
  }
}

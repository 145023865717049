import { withRouter } from 'react-router-dom'
import React from 'react'
import { connect } from 'react-redux'
import intl from 'react-intl-universal'

const mapStateToProps = state => ({
  order: state.order.data
})

class StatusBarComponent extends React.Component {
  render() {
    const statuses = [
      {name: 'new', label: intl.get('Nowe')},
      {name: 'processed', label: intl.get('W trakcie realizacji')},
      {name: 'sent', label: intl.get('Wysłane')},
      {name: 'delivered', label: intl.get('Odebrane')},
      {name: 'cancel', label: intl.get('Anulowane')}
    ]
    const { order } = this.props
    const { order_state } = order
    return(
      <div id="status_bar">
        {statuses.map((status) => {
          const class_string = `status_bar__step ${order_state === status.name ? 'active' : ''}`
          return (
            <div className={class_string}
                 data-value={status.name}
                 key={status.name}>
              {status.label}
            </div>
          )
        })}
      </div>
    )
  }
}
const StatusBar = connect(mapStateToProps)(StatusBarComponent)

export default withRouter(StatusBar)

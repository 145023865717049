import { SEND_MESSAGE, REVEAL_NAME, LOGOUT_USER, SET_LANG, API_ERROR} from '../constants/ActionTypes'
import { push } from 'connected-react-router'
import Odoo from '../utils/Odoo'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { setQueries } from '../utils/Tools'

function api_error(dispatch, e){
  if (e.response && e.response.status === 403){
    dispatch({
      type: LOGOUT_USER
    })
  } else {
    dispatch({
      type: API_ERROR,
      message: e.message
    })
  }
}

export function sendMessage(order_id, html, partner_id, token) {
  return dispatch => {
    let params = {
      order_id,
      html,
      partner_id,
      client_token: token
    }
    Odoo.call('order/send_message', params).then(res => {
      dispatch({
        type: SEND_MESSAGE,
        payload: res
      })
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}

export function revealName(name, client_token) {
  return dispatch => {
    let params = {
      name,
      client_token
    }
    Odoo.call('order/reveal_name', params).then(res => {
      dispatch({
        type: REVEAL_NAME,
        payload: res.result
      })
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}

export function toRma(name, client_token) {
  return dispatch => {
    let params = {
      name,
      client_token
    }
    Odoo.call('order/reveal_name', params).then(res => {
      dispatch({
        type: REVEAL_NAME,
        payload: res.result
      })
    }).then(res =>{
      document.querySelector('.rma.button').click()
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}

export function printInvoice(order_id, name) {
  return dispatch => {
    let params = {
      order_id,
      name
    }
    Odoo.call('order/print_invoice', params).then(res => {
      dispatch({
        type: REVEAL_NAME,
        payload: (res.order && res.order.result) || false
      })
      if (!res.pdf)
        return
      let pdfWindow = window.open("faktura")
      if(pdfWindow == null){
        Popup.alert(intl.get('Przeglądarka zablokowała wyświetlenie dokumentu. \nWyłącz blokowanie wyskakujących okien dla tej strony i spróbuj ponownie.'))
      } else {
        pdfWindow.document.write(
            "<iframe width='100%' style='margin-top: -4px; border: 0 none;' height='100%' src='data:application/pdf," +
            escape(res.pdf) + "'></iframe>"
        )
      }
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}

export function selectLang(lang) {
  const query = setQueries({
    lang: lang
  })
  return dispatch => {
    dispatch({
      type: SET_LANG,
      payload: lang
    })
    dispatch(push({
      search: query
    }))
  }
}

import React from 'react'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import { history } from '../store'
import Spinner from '../components/Spinner'
import App from '../components/App'
import LoginForm from '../components/LoginForm'
import Order from '../components/Order'

const mapStateToProps = state => ({
  authenticated: state.auth.session.token !== false,
})

class RoutesComponent extends React.Component {
  render() {
    const {authenticated} = this.props
    return (
      <ConnectedRouter history={history}>
        <App>
          <Spinner/>
          { authenticated &&
            <Route path={`${window.base_url || ''}/:token?`} component={Order}/>
          }
          { !authenticated &&
            <Route path={`${window.base_url || ''}/:token?`} component={LoginForm}/>
          }
        </App>
      </ConnectedRouter>
    )
  }
}
const Routes = connect(mapStateToProps)(RoutesComponent)

export default Routes

import React from 'react'
import intl from 'react-intl-universal'

class Message extends React.Component {
  render() {
    const { message } = this.props
    return (
      <div className="message">
        <div className="message-avatar-container">
          {message.avatar &&
            <img alt="avatar" className="message-avatar" src={'data:image/png;base64,' + message.avatar}/>
          }
          {!message.avatar &&
            <div className="message-avatar"/>
          }
        </div>
        <div className="message-content">
          <div className="message-meta">
            {intl.get('Od')}: {message.author}, {intl.get('dnia:')} {message.date.slice(0, -3)}
          </div>
          {message.subject && <h5>{message.subject}</h5>}
          {message.body &&
            <div className="message-body" dangerouslySetInnerHTML={{__html: message.body}} />
          }
        </div>
      </div>
    );
  }
}

export default Message;

import { withRouter } from 'react-router-dom'
import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { logUserIn } from '../actions/AuthActions'
import Spinner from './Spinner'

const mapStateToProps = state => {
  return{
    invalid: state.auth.invalid,
    authenticated: state.auth.session.token !== false
  }
}

const mapDispatchToProps = dispatch => ({
    logInAction: (token) => dispatch(
      logUserIn(token)
    )
})

class LoginFormComponent extends React.Component {
  componentDidMount(){
    const { logInAction } = this.props
    const token = this.props.match.params.token
    logInAction(token)
  }

  render() {
    const { authenticated, invalid } = this.props
    return (
      <div className="center_wrapper">
        { !authenticated &&
          invalid ? (
            <h2>{intl.get('Wprowadzony link jest niepoprawny - nie znaleziono zamówienia.')}</h2>
          ) : (
            <Spinner/>
          )
        }
      </div>
    )
  }
}
const LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent)
export default withRouter(LoginForm)

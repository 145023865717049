import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { sendMessage } from '../actions/OrderActions'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { Editor } from 'react-draft-wysiwyg'

const editor_opts = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline']
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered']
  },
  textAlign: {
    inDropdown: false,
    options: ['left', 'center', 'right', 'justify']
  },
  colorPicker: {
    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
      'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
      'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
      'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
      'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
      'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)']
  },
  link: {
    inDropdown: false,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link', 'unlink']
  },
  history: {
    inDropdown: false,
    options: ['undo', 'redo']
  }
}

const mapStateToProps = state => ({
  order: state.order.data
})

const mapDispatchToProps = dispatch => ({
  sendMessage: (order_id, html, partner_id, client_token) => dispatch(sendMessage(order_id, html, partner_id, client_token))
})

class MessageComposerComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      editorState: EditorState.createEmpty()
    }
  }

  toggleVisible = () => {
    const { visible } = this.state
    this.setState({
      visible: !visible
    })
  }

  sendMessage = (e) => {
    e.currentTarget.disabled=true
    const { sendMessage, order, token } = this.props
    const { editorState, visible } = this.state
    let html = stateToHTML(editorState.getCurrentContent())
    sendMessage(order.id, html, order.partner_id, token)
    this.setState({
      visible: !visible,
      editorState: EditorState.createEmpty()
    })
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState: editorState
    })
  }

  render() {
    const { editorState, visible } = this.state;
    const { order } = this.props;
    if(order){
      if (visible){
        return (
            <div className="message-composer">
              <div>
                <Editor
                  className="composer-content"
                  toolbar={editor_opts}
                  editorState={editorState}
                  onEditorStateChange={this.onEditorStateChange}
                />
                <div className="composer-footer">
                  <button className="composer-send" onClick={this.sendMessage}>{intl.get('Wyślij wiadomość')}</button>
                </div>
              </div>
            </div>
        )
      } else {
        return (
            <button className="message-composer-button" onClick={this.toggleVisible}>{intl.get('Nowa wiadomość')}</button>
        )
      }
    }
  }
}

const MessageComposer = connect(mapStateToProps, mapDispatchToProps)(MessageComposerComponent)
export default MessageComposer

import { withRouter } from 'react-router-dom'
import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  authenticated: state.auth.session.token !== false,
})

class HeaderComponent extends React.Component {

  render() {
    return(
      <header>
        <div className="logo"/>
      </header>
    )
  }
}
const Header = connect(mapStateToProps)(HeaderComponent)

export default withRouter(Header)

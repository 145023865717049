import React from 'react';

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: props.from,
      to: props.to,
      cities: props.cities,
      delivered: props.delivered
    };
  }

  initializeMap(centerLat, centerLon) {
    const map = new window.L.Map('myMap', {
      center: new window.L.LatLng(centerLat, centerLon),
      zoom: 6,
    });

    window.map = map;

    window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    }).addTo(map);
  }

  mapMarkers() {
    const { from, to } = this.state;
    window.map.eachLayer(function (layer) {
      if (layer instanceof window.L.Marker || layer instanceof window.L.Polyline) {
        window.map.removeLayer(layer);
      }
    });

    const geocode = async (location) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(location)}`
        );
        const data = await response.json();
        if (data.length > 0) {
          //console.log(`Geocoded ${location}:`, data[0]);
          return { lat: parseFloat(data[0].lat), lon: parseFloat(data[0].lon) };
        } else {
          console.warn(`No results found for ${location}`);
          return null;
        }
      } catch (error) {
        console.error(`Failed to fetch geocode for ${location}:`, error);
        return null;
      }
    };

    const addMarker = (lat, lon, color) => {
      return window.L.circleMarker([lat, lon], {
        color: color,
        radius: 8,
      }).addTo(window.map);
    };

    Promise.all([geocode(from), geocode(to)]).then(([fromLocation, toLocation]) => {
      if (fromLocation) {
        addMarker(fromLocation.lat, fromLocation.lon, '#00dc1b');
      } else {
        console.error('Failed to geocode the "from" location.');
      }

      if (toLocation) {
        addMarker(toLocation.lat, toLocation.lon, 'red');
      } else {
        console.error('Failed to geocode the "to" location.');
      }

      if (fromLocation && toLocation) {
        // LINE
        window.L.polyline([
          [fromLocation.lat, fromLocation.lon],
          [toLocation.lat, toLocation.lon]
        ], { color: 'blue', weight: 3 }).addTo(window.map);

        const bounds = window.L.latLngBounds([
          [fromLocation.lat, fromLocation.lon],
          [toLocation.lat, toLocation.lon],
        ]);
        window.map.fitBounds(bounds);
      }
    });
  }

  loadScript(url, callback) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = url;

    script.onload = callback;

    document.getElementsByTagName('head')[0].appendChild(script);
  }

  componentDidMount() {
    // Leaflet
    const scriptURL = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js';
    const self = this;

    if (document.querySelector(`script[src="${scriptURL}"]`) === null) {
      this.loadScript(scriptURL, function () {
        self.initializeMap(51.9061197, 19.4204499); // Center - Poland by default
        self.mapMarkers();
      });

      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }

  componentDidUpdate(prevProps) {
    this.mapMarkers();
  }

  render() {
    return <div id="myMap" style={{ width: '100%', height: '500px' }}></div>;
  }
}

export default Map;
